
interface IDefaults {
	altField?: string;
	altFormat?: string;
}

function bindDatepicker(opts: {}): void {
	$(".field.date input[type=text]").each(function(i: number, n: Element) {

		var defaults: IDefaults = {};
		if ($(this).siblings('input[type="hidden"]').length) {
			defaults.altField = '#' + $(this).siblings('input[type="hidden"]')[0].id;
			defaults.altFormat = 'yy-mm-dd';
		}
		$(this).datepicker($.extend(defaults, opts));
	});
}

function displayErrorContainerForForm($form: JQuery): JQuery {
	var errors: JQuery = $form.parent().find('.errors') as JQuery<HTMLElement>;
	if (!errors.length) {
		errors = $(
			'<div class="errors icon" role="alert">' +
			'<strong class="error-title">' +
			Alkami.Localization.SiteText['GeneralErrorMessage']
			+ '</strong>' +
			'<div class="exception"></div>' +
			'<ul class="error-list"></ul>' +
			'</div>'
		);

		// The errorWrapper class allows placement of errors in a form <div class="error-wrapper"></div>
		// If this class doesn't exist, prepend errors to the top of the form by default
		var errorWrapper: JQuery = $form.find('.error-wrapper') as JQuery<HTMLElement>;
		if (errorWrapper.length) {
			errorWrapper.prepend(errors);
		} else {
			$form.prepend(errors);
		}
	} else {
		errors.removeClass('one');
	}
	var scrollToError = function(el: string, ms: number): void {
		var speed: number = (ms) ? ms : 400;
		$('html, body').animate({
			scrollTop: $(el).offset().top - 60
		}, speed);
	};
	if ($('div.errors').length !== 0) {
		scrollToError('div.errors', 400);
	}
	return errors;
}

function previewIcon(resource: any, field: string | HTMLElement, opts: any): Promise<any> {
	const updateElementId: string = (opts && opts.updateElementId) ? opts.updateElementId : 'preview_image';
	const fieldElement = (typeof field === 'string' ? document.querySelector(field) : field) as HTMLInputElement;
	const formElement: HTMLFormElement = fieldElement.form || fieldElement.closest('form') as HTMLFormElement;
	let resolve;
	let reject;
	const defer = new Promise(function(res, rej) { resolve = res; reject = rej; });


	const options = Object.assign({
		success: function(response: any): void {
			const text: string = response.responseText;
			const fe = fieldElement.closest('.field');
			const updatedElement = document.getElementById(updateElementId);

			if (!text.length || /Error/.test(text)) { // text will be blank in the case of 500 error, otherwise if it contains the term "Error"
				const error: string = text.length ? text.replace("Error: ", "") : "We were unable to process your upload. Please ensure your file is less than 5 MB and is of type JPG, GIF or PNG.";
				if (opts && opts.onError) {
					opts.onError.call(response, error);
					return reject(error);
				}
				displayErrorContainerForForm($(formElement) as JQuery<HTMLElement>); // TODO: refactor this guy to not require jQuery
				if (fe) {
					fe.classList.add('field_with_errors');
				}
				Array.from(formElement.querySelectorAll('.errors ul')).forEach(function(errorListElement) {
					errorListElement.innerHTML = `<li>${error}</li>`;
				});
				updatedElement.setAttribute('src', '/Image/PreviewImage?resource=&Height=50&Width=50'); // show "no image".
				fieldElement.value = ''; // reset the field.
				return reject(error);
			}

			Array.from(formElement.querySelectorAll('.errors')).forEach(function(errorElement) {
				errorElement.parentElement.removeChild(errorElement);
			});
			if (fe) {
				fe.classList.remove('field_with_errors');
			}

			updatedElement.setAttribute('src', `/Image/PreviewImage?resource=${response.responseText}&Height=50&Width=50`);
			return resolve(response.responseText);
		}
	}, opts) as any;

	$(field as any).ajaxFileUpload(options);
	return defer;
}
